import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh check-host`}</strong>{` -- checks if a certificate has been issued for a host`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh check-host <hostname> [--verbose,-v]
[--offline] [--ca-config=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh check-host`}</strong>{` checks if a certificate has been issued for a host.`}</p>
    <p>{`This command returns a zero exit status if the host has a certificate.
Otherwise, it returns 1.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`hostname`}</inlineCode>{`
The hostname of the server to check.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--verbose`}</strong>{`, `}<strong parentName="p">{`-v`}</strong>{`
Return "true" or "false" in the terminal.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Check that internal.example.com exists:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh check-host internal.smallstep.com
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      